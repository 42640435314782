<template>
  <v-container>
    <h2>Verbali di Taratura Valvola di Sicurezza</h2>
    <br />
    <span v-show="tab == 0">
      <v-expansion-panels accordion v-model="panel">
        <v-expansion-panel>
          <v-expansion-panel-header><h4>Ricerca</h4></v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-text-field
              outlined
              dense
              label="Matricola"
              v-model="matricola"
              clearable
              ref="matricola"
            ></v-text-field>
            <v-autocomplete
              :items="bpartners"
              v-model="c_bpartner_id"
              label="Cliente"
              hide-no-data
              hide-selected
              item-text="name"
              item-value="c_bpartner_id"
              :search-input.sync="bpSearch"
              outlined
              dense
              clearable
            ></v-autocomplete>
            <v-flex d-flex>
              <v-btn
                color="primary"
                @click="searchOrderlinesValvole"
                :disabled="!c_bpartner_id && !matricola"
                >Cerca</v-btn
              >
              <v-spacer></v-spacer>
              <v-switch
                label="Mostra ordini già spediti"
                v-model="isShipped"
              ></v-switch>
            </v-flex>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="orderlines.length > 0">
          <v-expansion-panel-header><h4>Ordini</h4></v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              :items="orderlines"
              :headers="headersOrderlines"
              item-value="c_orderline_id"
              dense
              @click:row="getVerbaliTaraturaValvola"
            >
              <template v-slot:item.dateOrdered="{ item }">
                {{ dateToItalianFormat(item.dateOrdered) }}
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="verbali != null">
          <v-expansion-panel-header><h4>Verbali</h4></v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-flex d-flex>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="nuovoVerbale">
                <v-icon>mdi-newspaper-plus</v-icon>Nuovo Verbale
              </v-btn>
            </v-flex>
            <v-data-table
              :items="verbali"
              :headers="headersVerbali"
              item-value="fct_verbale_tarat_valvola_id"
              dense
            >
              <template v-slot:item.dataVerbale="{ item }">
                {{ dateToItalianFormat(item.dataVerbale) }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom pa-5>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click="copiaVerbale(item)" icon>
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>Copia verbale</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :disabled="isModificaDisable(item.dataVerbale)"
                      v-on="on"
                      @click="modificaVerbale(item)"
                      icon
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Modifica Verbale</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      @click="
                        downloadVerbale(
                          { ...item, matricola: orderline.matricola },
                          'Verbale Taratura Valvola'
                        )
                      "
                      icon
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </template>
                  <span>Scarica Verbale</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      @click="
                        downloadVerbale(
                          { ...item, matricola: orderline.matricola },
                          'Etichetta Taratura Valvola'
                        )
                      "
                      icon
                    >
                      <v-icon>mdi-tag-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Scarica Etichetta</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <br />
      <v-flex d-flex>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="resetSearch">Reset Ricerca</v-btn>
      </v-flex>
    </span>
    <span v-show="tab == 1">
      <v-btn text @click="goBack()"
        ><v-icon>mdi-chevron-left</v-icon>Indietro</v-btn
      >
      <Window
        name="Verbale di Taratura Valvola di Sicurezza"
        ref="window"
        v-model="fct_verbale_tarat_valvola_id"
        hide-copy
        @save="onSave"
        @delete="onDelete"
        @change="onChange"
        @dataFetched="onChange"
      ></Window>
    </span>
  </v-container>
</template>

<script>
/* eslint-disable */
import Window from "../components/Window.vue";
import mixin from "../mixin/MixingCommonComp";

export default {
  mixins: [mixin],
  components: { Window },
  data() {
    return {
      panel: 0,
      tab: 0,
      bpSearch: "",
      bpartners: [],
      orderlines: [],
      orderline: null,
      verbali: null,
      matricola: null,
      c_bpartner_id: 0,
      isShipped: false,
      fct_verbale_tarat_valvola_id: 0,
      headersOrderlines: [
        { text: "Matricola", value: "matricola" },
        { text: "Cliente", value: "bpname" },
        { text: "N° Documento", value: "documentNo" },
        { text: "Data Ordine", value: "dateOrdered" },
      ],
      headersVerbali: [
        { text: "Nome", value: "nomeVerbale" },
        { text: "Data", value: "dataVerbale" },
        { text: "Azioni", value: "actions" },
      ],
    };
  },
  methods: {
    searchOrderlinesValvole() {
      let parameters = {};
      parameters.data = {
        c_bpartner_id: this.c_bpartner_id,
        matricola: this.matricola,
        isShipped: this.isShipped,
      };
      parameters.idempiereRestPath = "seritec/searchOrderlinesValvole";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.orderlines = response.data.orderlines;
        this.panel = 1;
      });
    },
    getVerbaliTaraturaValvola(orderline) {
      this.orderline = orderline;

      let parameters = {};
      parameters.data = {
        c_orderline_id: orderline.c_orderline_id,
      };
      parameters.idempiereRestPath = "seritec/getVerbaliTaraturaValvola";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.verbali = response.data.verbali;
        this.panel = 2;
      });
    },
    async nuovoVerbale() {
      this.fct_verbale_tarat_valvola_id = 0;

      await this.delay(100);

      /* Dati Ordine */
      this.$refs.window.setValue(
        "C_OrderLine_ID",
        this.orderline.c_orderline_id
      );

      this.$refs.window.setValue("IsInail", this.orderline.isInail ? "Y" : "N");

      /* Dati Valvole */
      this.$refs.window.setValue(
        "FCT_Valvola_ID",
        this.orderline.fct_valvola_id
      );
      this.$refs.window.setValue("Fabbricante", this.orderline.fabbricante);
      this.$refs.window.setValue("Modello", this.orderline.modello);
      this.$refs.window.setValue("Matricola", this.orderline.matricola);
      this.$refs.window.setValue("TipoValvola", this.orderline.tipoValvola);
      this.$refs.window.setValue("DNEntrata", this.orderline.dnEntrata);
      this.$refs.window.setValue("DNUscita", this.orderline.dnUscita);
      this.$refs.window.setValue(
        "PressioneApertura",
        this.orderline.pressioneApertura
      );
      this.$refs.window.setValue(
        "PressioneChiusura",
        this.orderline.pressioneChiusura
      );
      this.$refs.window.setValue("DiametroOrifizio", this.orderline.diametroOrifizio);

      /* Dati Ditta */
      this.$refs.window.setValue("ditta", this.orderline.datiDitta.ditta);
      this.$refs.window.setValue("viaditta", this.orderline.datiDitta.viaDitta);

      let comuneDitta = this.orderline.datiDitta.comuneDitta;
      if (this.orderline.datiDitta.capDitta) {
        comuneDitta = this.orderline.datiDitta.capDitta + " " + comuneDitta;
      }

      if (this.orderline.datiDitta.provinciaDitta) {
        comuneDitta += " (" + this.orderline.datiDitta.provinciaDitta + ")";
      }

      this.$refs.window.setValue("comuneditta", comuneDitta);

      /* Dati Personali */
      this.$refs.window.setValue(
        "DittaVerifica",
        this.orderline.datiPersonali.ditta
      );
      this.$refs.window.setValue(
        "ViaVerifica",
        this.orderline.datiPersonali.viaVerifica
      );

      let comuneVerifica = this.orderline.datiPersonali.comuneVerifica;
      if (this.orderline.datiPersonali.capVerifica) {
        comuneVerifica =
          this.orderline.datiPersonali.capVerifica + " " + comuneVerifica;
      }

      if (this.orderline.datiPersonali.provinciaVerifica) {
        comuneVerifica +=
          " (" + this.orderline.datiPersonali.provinciaVerifica + ")";
      }

      this.$refs.window.setValue("ComuneVerifica", comuneVerifica);

      this.$refs.window.setValue(
        "luogo",
        this.orderline.datiPersonali.comuneVerifica
      );

      this.$refs.window.setValue(
        "dataver",
        new Date().toISOString().substring(0, 10)
      );

      this.tab = 1;
    },
    resetSearch() {
      this.fct_verbale_tarat_valvola_id = 0;
      this.matricola = null;
      this.c_bpartner_id = 0;
      this.isShipped = false;
      this.orderlines = [];
      this.verbali = null;
      this.panel = 0;
      setTimeout(() => {
        this.$refs.matricola.focus();
      }, 50);
    },
    isModificaDisable(dataVerbale) {
      let dataV = new Date(dataVerbale).getTime();
      let dataO = new Date().getTime();
      return !(dataO - dataV < 2678400000 || this.isShipped);
    },
    copiaVerbale(item) {
      this.fct_verbale_tarat_valvola_id = item.fct_verbale_tarat_valvola_id;
      this.$refs.window.setCopyValue("nomeverbale", null);
      this.$refs.window.copyRecord();
      this.tab = 1;
    },
    modificaVerbale(item) {
      this.fct_verbale_tarat_valvola_id = item.fct_verbale_tarat_valvola_id;
      this.tab = 1;
    },
    downloadVerbale(item, nomeStampa) {
      this.showLoadingDialog(true, "Download Verbale...");
      console.log(item);
      let parameters = {};
      parameters.data = {
        parameters: JSON.stringify({
          RECORD_ID: item.fct_verbale_tarat_valvola_id,
        }),
        nomeStampa: nomeStampa,
      };
      parameters.idempiereRestPath = "print/addPrintJob";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        if (response.data.hasOwnProperty("file")) {
          const linkSource =
            "data:application/pdf;base64," + response.data.file;
          const downloadLink = document.createElement("a");
          const fileName = "VDS" + item.matricola + ".pdf";

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          this.showLoadingDialog(false);
        } else {
          let errorMsg = "Download non riuscito";
          if (response.data.hasOwnProperty("message")) {
            errorMsg = errorMsg + "\n" + response.data.message;
          }
          this.showErrorDialog(errorMsg);
        }
      });
    },
    onSave() {
      let item = {
        fct_verbale_tarat_valvola_id: this.fct_verbale_tarat_valvola_id,
        nomeVerbale: this.$refs.window.getValue("nomeverbale"),
        matricola: this.$refs.window.getValue("Matricola"),
      };
      this.downloadVerbale(item, "Verbale Taratura Valvola");
      this.getVerbaliTaraturaValvola(this.orderline);
      this.tab = 0;
    },
    onDelete() {
      this.getVerbaliTaraturaValvola(this.orderline);
      this.tab = 0;
    },
    onChange(item) {
      if (
        item.field.Column.ColumnName == "AnniProssimaTaratura" ||
        item.field.Column.ColumnName == "dataver"
      ) {
        this.onChangeProssimaTaratura();
      } else if (item.field.Column.ColumnName == "LuogoVerifica") {
        this.onChangeLuogoVerifica();
      }
    },
    onChangeProssimaTaratura() {
      let dataVerifica = this.$refs.window.getValue("dataver");
      let anniProssimaTaratura = this.$refs.window.getValue(
        "AnniProssimaTaratura"
      );

      if (dataVerifica != null && anniProssimaTaratura != null) {
        let date = new Date(dataVerifica);
        date.setFullYear(date.getFullYear() + parseInt(anniProssimaTaratura));
        this.$refs.window.setValue(
          "DataProssimaTaratura",
          date.toISOString().substring(0, 10)
        );
      }
    },
    onChangeLuogoVerifica() {
      let luogoVerifica = this.$refs.window.getValue("LuogoVerifica");
      if (luogoVerifica == "S") {
        this.$refs.window.setValue(
          "DittaVerifica",
          this.orderline.datiPersonali.ditta
        );
        this.$refs.window.setValue(
          "ViaVerifica",
          this.orderline.datiPersonali.viaVerifica
        );

        let comuneVerifica = this.orderline.datiPersonali.comuneVerifica;
        if (this.orderline.datiPersonali.capVerifica) {
          comuneVerifica =
            this.orderline.datiPersonali.capVerifica + " " + comuneVerifica;
        }

        if (this.orderline.datiPersonali.provinciaVerifica) {
          comuneVerifica +=
            " (" + this.orderline.datiPersonali.provinciaVerifica + ")";
        }

        this.$refs.window.setValue("ComuneVerifica", comuneVerifica);

        this.$refs.window.setValue(
          "luogo",
          this.orderline.datiPersonali.comuneVerifica
        );
      } else {
        /* Dati Ditta */
        this.$refs.window.setValue(
          "DittaVerifica",
          this.orderline.datiDitta.ditta
        );
        this.$refs.window.setValue(
          "ViaVerifica",
          this.orderline.datiDitta.viaDitta
        );

        let comuneDitta = this.orderline.datiDitta.comuneDitta;
        if (this.orderline.datiDitta.capDitta) {
          comuneDitta = this.orderline.datiDitta.capDitta + " " + comuneDitta;
        }

        if (this.orderline.datiDitta.provinciaDitta) {
          comuneDitta += " (" + this.orderline.datiDitta.provinciaDitta + ")";
        }

        this.$refs.window.setValue("ComuneVerifica", comuneDitta);

        this.$refs.window.setValue(
          "luogo",
          this.orderline.datiDitta.comuneDitta
        );
      }
    },
    goBack() {
      this.getVerbaliTaraturaValvola(this.orderline);
      this.tab = 0;
    },
    delay(ms) {
      return new Promise((res) => setTimeout(res, ms));
    },
  },
  watch: {
    bpSearch: function () {
      if (this.bpSearch == null || this.bpSearch == "") return;
      if (this.bpSearch.length >= 3) {
        let data = {};
        data.name = this.bpSearch.toLowerCase();
        data.mostraOrdiniFatturati = this.mostraOrdini;
        let parameters = {};
        parameters.data = data;
        parameters.idempiereRestPath = "vecos/cercaCliente";
        this.$store
          .dispatch("callIdempiereRest", parameters)
          .then((response) => {
            this.bpartners = response.data.clienti;
          });
      }
    },
  },
};
</script>

<style>
</style>